@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-slate-900 text-base bg-white;
    @apply overflow-hidden;
    padding-bottom: env(safe-area-inset-bottom);
    overscroll-behavior-x: none;
  }

  a {
    @apply text-th-orange-beam hover:text-th-orange-dark1 font-medium;
    text-decoration: none;
  }

  button {
    @apply bg-slate-300;
    @apply rounded-8;
    @apply py-1.5 px-3;
    @apply border;
  }

  textarea {
    @apply resize-none;
  }
}

/* preflight overrides */
@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  input,
  select {
    @apply ring-0;
    @apply ring-black;
    @apply outline-0;
    @apply outline-black;
    outline: none;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }

  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  input:focus,
  select:focus {
    @apply ring-0;
    @apply ring-black;
    @apply outline-0;
    @apply outline-black;
    outline: none;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }

  [type='text']:focus-visible,
  [type='email']:focus-visible,
  [type='url']:focus-visible,
  [type='password']:focus-visible,
  [type='number']:focus-visible,
  [type='date']:focus-visible,
  [type='datetime-local']:focus-visible,
  [type='month']:focus-visible,
  [type='search']:focus-visible,
  [type='tel']:focus-visible,
  [type='time']:focus-visible,
  [type='week']:focus-visible,
  [multiple]:focus-visible,
  textarea:focus-visible,
  input:focus-visible,
  select:focus-visible {
    @apply ring-0;
    @apply ring-black;
    @apply outline-0;
    @apply outline-black;
    outline: none;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }

  [type='checkbox']:focus,
  [type='radio']:focus {
    @apply ring-0;
    @apply ring-black;
    @apply outline-0;
    @apply outline-black;
  }
}

@layer components {
  .input-base {
    @apply w-full relative;
    @apply bg-transparent;
    @apply text-base;
    @apply placeholder-th-text-hint;
    @apply outline-0 border-0;
    @apply py-[14px] px-[12px];
    @apply font-medium;
  }

  .input-base.disabled {
    @apply cursor-not-allowed;
    @apply text-th-text-disabled;
    -webkit-text-fill-color: theme('colors.th.text.disabled');
  }

  .input-base.error {
    @apply border-th-red-warning;
  }
}

@layer utilities {
  .headline {
    @apply flex justify-between items-center;
  }

  .vflex {
    @apply flex flex-col;
  }

  .flex-equal {
    @apply flex;
    @apply [&>*]:basis-1 [&>*]:grow;
  }

  .flex-force-equal {
    @apply flex;
    @apply [&>*]:basis-1 [&>*]:grow [&>*]:min-w-0;
  }

  .sticky-actions {
    @apply sticky w-full bottom-0 flex flex-col-reverse items-center gap-1 py-3 px-5 bg-white border-t [&>*]:w-full;
  }

  .footer-actions {
    @apply absolute w-full bottom-0 flex flex-col-reverse items-center gap-1 py-3 px-5 bg-white border-t [&>*]:w-full;
  }

  tr:hover .row-hover-accent {
    @apply text-th-orange-light1;
  }

  .clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .clamp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .clamp-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .hitbox {
    @apply p-2 -m-2;
    @apply cursor-pointer;
  }

  .hitbox-lg {
    @apply p-4 -m-4;
    @apply cursor-pointer;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

#__next {
  height: 100%;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  -webkit-tap-highlight-color: transparent;
}

nextjs-portal {
  display: none;
}

* {
  box-sizing: border-box;
}
